import React, { Component } from "react";
import { history } from "helpers";
import { Link, Redirect } from "react-router-dom";
import "./index.scss";
import jwt_decode from "jwt-decode";
import InnovationsDropdown from "../InnovationsDropdown/InnovationsDropDown";
const logo = require("assets/images/Landing/logo.png");

export class Navbar extends Component {
  constructor() {
    super();
    this.myRef = React.createRef();
    // const token = localStorage.getItem("token");
    // console.log(token);
    // let loggedIn = true;
    // if (token == null) {
    //   loggedIn = false;
    // }
    // this.state = {
    //   loggedIn,
    // };
  }

  state = {
    menuOpen: false,
    userDetails: {},
    userType: null,
    innovativeDropDown: false,
  };
  componentDidMount() {
    if (localStorage.getItem("user-info")) {
      let details = JSON.parse(localStorage.getItem("user-info"));
      this.setState({
        userDetails: details.data,
        userType: jwt_decode(details.data.token).userType,
      });
    }
    this.setState({
      menuOpen: false,
    });
    // window.addEventListener("resize", this.updateMenu);
    // window.addEventListener("scroll", this.updateMenu);
    // window.addEventListener("click", this.updateMenuOnClick);
  }
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (localStorage.getItem("user-info")) {
        let details = JSON.parse(localStorage.getItem("user-info"));
        this.setState({
          userDetails: details.data,
          userType: jwt_decode(details.data.token).userType,
        });
      }
    }
  }
  updateMenu = () => {
    this.setState({
      menuOpen: false,
    });
  };

  updateMenuOnClick = (e) => {
    if (e.target !== this.myRef.current) {
      this.updateMenu();
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateMenu);
    window.removeEventListener("scroll", this.updateMenu);
    window.removeEventListener("click", this.updateMenuOnClick);
  }

  handleNavMenu = () => {
    this.setState({
      menuOpen: !this.state.menuOpen,
    });
  };

  handlelogout = () => {
    localStorage.clear();
    history.push("/landing/home");
    window.location.reload();
  };

  render() {
    let { userType } = this.state;
    let isLoggedIn = false;

    isLoggedIn =
      history.location.pathname &&
      (history.location.pathname.includes("/list") ||
        history.location.pathname.includes("/catalogue") ||
        history.location.pathname.includes("/UploadHistory") ||
        history.location.pathname.includes("/product") ||
        history.location.pathname.includes("/cart") ||
        history.location.pathname.includes("/order-confirm") ||
        history.location.pathname.includes("/errors") ||
        history.location.pathname.includes("/order-history"));
    return (
      <div className="navbar">
        <div className="container-sa navbar-container">
          <div className="logo">
            <img
              src={logo}
              alt="logo"
              onClick={() => {
                history.push("/landing/home");
              }}
            />
          </div>
          <div className="nav-links">
            <ul>
              <li>
                <Link
                  to="/landing/home"
                  className={`${
                    history.location.pathname === "/landing/home"
                      ? "active"
                      : ""
                  }`}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/landing/who-we-are"
                  className={`${
                    history.location.pathname === "/landing/who-we-are"
                      ? "active"
                      : ""
                  }`}
                >
                  Who We Are
                </Link>
              </li>
              <li className="mr-2">
                <Link
                  to="/landing/innovative-solutions/default"
                  className={`${
                    history.location.pathname ===
                    "/landing/innovative-solutions/default"
                      ? "active"
                      : ""
                  }`}
                >
                  Innovative Solutions
                </Link>
                {/* <InnovationsDropdown /> */}
                {/* <Link
                  to="/landing/innovative-solutions"
                  className={`${history.location.pathname ===
                      "/landing/innovative-solutions"
                      ? "active"
                      : ""
                    }`}
                >
                  Innovative Solutions
                </Link> */}
              </li>
              <Link to="/landing/innovative-solutions"></Link>
              <li style={{ marginLeft: -35 }}>
                <Link
                  to="/landing/contact-us"
                  className={`${
                    history.location.pathname === "/landing/contact-us"
                      ? "active"
                      : ""
                  }`}
                >
                  Contact us
                </Link>
              </li>
              {userType ? (
                <li>
                  <Link
                    to={
                      userType == 2
                        ? "/marketplace/dashboard/list"
                        : userType == 3
                        ? "/marketplace/dashboard/catalogue"
                        : "/view/list"
                    }
                    className={`${isLoggedIn ? "active" : ""}`}
                  >
                    {userType == 2 || userType == 3 ? "Products" : "Users"}
                  </Link>
                </li>
              ) : null}
              <li>
                {userType ? (
                  <Link
                    // to="/landing/login"
                    // className={`${
                    //   history.location.pathname === "/dashboard/list" ||
                    //   "/retailer/dashboard/catalogue" ||
                    //   "/dashboard/uploadhistory"
                    //     ? "active"
                    //     : ""
                    // }`}
                    onClick={this.handlelogout}
                  >
                    Logout
                  </Link>
                ) : (
                  <div>
                    {/* <Link
                    to="/landing/login"
                    className={`${
                      history.location.pathname === "/landing/login"
                        ? "active"
                        : ""
                    }`}
                  >
                    Login
                  </Link> */}
                  </div>
                )}
              </li>
            </ul>
          </div>
          <div className="menu-items">
            {this.state.menuOpen && (
              <div className="nav-links-menu">
                <ul>
                  <li>
                    <Link
                      to="/landing/home"
                      onClick={this.updateMenuOnClick}
                      className={`${
                        history.location.pathname === "/landing/home"
                          ? "active"
                          : ""
                      }`}
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/landing/who-we-are"
                      onClick={this.updateMenuOnClick}
                      className={`${
                        history.location.pathname === "/landing/who-we-are"
                          ? "active"
                          : ""
                      }`}
                    >
                      Who We Are
                    </Link>
                  </li>
                  <li
                    onClick={() =>
                      this.setState({
                        innovativeDropDown: !this.state.innovativeDropDown,
                      })
                    }
                  >
                    <Link
                      to={"#"}
                      className={`${
                        history.location.pathname ===
                        "/landing/innovative-solutions"
                          ? "active"
                          : ""
                      }`}
                    >
                      Innovative Solutions
                      {this.state.innovativeDropDown ? (
                        <div className="innovative-dropdown">
                          <Link
                            onClick={this.updateMenuOnClick}
                            className={`${
                              history.location.pathname ===
                              "/landing/innovative-solutions/creatorMobile"
                                ? "active"
                                : ""
                            }`}
                            to={{
                              pathname:
                                "/landing/innovative-solutions/creatorMobile",
                              state: { sendfromactive: true },
                            }}
                          >
                            <p className="mobile-dropdown">OLE Creator</p>
                          </Link>

                          <Link
                            onClick={this.updateMenuOnClick}
                            className={`${
                              history.location.pathname ===
                              "/landing/innovative-solutions/viewerMobile"
                                ? "active"
                                : ""
                            }`}
                            to={{
                              pathname:
                                "/landing/innovative-solutions/viewerMobile",
                              state: { sendfromactiveViewer: true },
                            }}
                          >
                            <p className="mobile-dropdown">OLE Viewer</p>
                          </Link>

                          <Link
                            onClick={this.updateMenuOnClick}
                            className={`${
                              history.location.pathname ===
                              "/landing/innovative-solutions/marketplaceMobile"
                                ? "active"
                                : ""
                            }`}
                            to={{
                              pathname:
                                "/landing/innovative-solutions/marketplaceMobile",
                              state: { sendfromactivemarket: true },
                            }}
                          >
                            <p className="mobile-dropdown">OLE MarketPlace</p>
                          </Link>
                        </div>
                      ) : (
                        <></>
                      )}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/landing/contact-us"
                      onClick={this.updateMenuOnClick}
                      className={`${
                        history.location.pathname === "/landing/contact-us"
                          ? "active"
                          : ""
                      }`}
                    >
                      Contact us
                    </Link>
                  </li>
                  {userType ? (
                    <li>
                      <Link
                        to={
                          userType == 2
                            ? "/marketplace/dashboard/list"
                            : userType == 3
                            ? "/marketplace/dashboard/catalogue"
                            : "/view/list"
                        }
                        className={`${
                          history.location.pathname ===
                            "/marketplace/dashboard/list" ||
                          history.location.pathname ===
                            "/marketplace/dashboard/catalogue" ||
                          history.location.pathname === "/view/list"
                            ? "active"
                            : ""
                        }`}
                      >
                        {userType == 2 || userType == 3 ? "Products" : "Users"}
                      </Link>
                    </li>
                  ) : null}
                  <li>
                    {userType ? (
                      <Link
                        // to="/landing/login"
                        // className={`${
                        //   history.location.pathname === "/dashboard/list" ||
                        //   "/retailer/dashboard/catalogue" ||
                        //   "/dashboard/uploadhistory"
                        //     ? "active"
                        //     : ""
                        // }`}
                        onClick={this.handlelogout}
                      >
                        Logout
                      </Link>
                    ) : (
                      ""
                    )}
                  </li>
                </ul>
              </div>
            )}
            <div onClick={this.handleNavMenu} className="hamburger-container">
              <span
                ref={this.myRef}
                className={`icon-menu ${this.state.menuOpen ? "active" : ""}  `}
              ></span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
