import React from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import "./InnovationsDropdown.scss";
import { Link } from "react-router-dom";
export default class Example extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.state = {
      dropdownOpen: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  onMouseEnter() {
    this.setState({ dropdownOpen: true });
  }

  onMouseLeave() {
    this.setState({ dropdownOpen: false });
  }

  toggleDefault = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  toggleCreator = () => {
    window.scrollTo({
      top: 950,
      behavior: "smooth",
    });
  };

  toggleViewer = () => {
    window.scrollTo({
      top: 1000,
      behavior: "smooth",
    });
  };

  toggleMarketPlace = () => {
    window.scrollTo({
      top: 1050,
      behavior: "smooth",
    });
  };

  render() {
    return (
      <Dropdown
        className="dropdown-btn"
        onMouseOver={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
      >
        <DropdownToggle>
          <Link
            to={{
              pathname: "/landing/innovative-solutions/default",
            }}
          >
            <div style={{ marginTop: -5 }} onClick={this.toggleDefault}>
              Innovative Solutions
            </div>
          </Link>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu">
          <Link
            to={{
              pathname: "/landing/innovative-solutions/creator",
              state: { sendfromactive: true },
            }}
          >
            <p className="dropdown-item" onClick={this.toggleCreator}>
              OLE Creator
            </p>
          </Link>
          <Link
            to={{
              pathname: "/landing/innovative-solutions/viewer",
              state: { sendfromactiveViewer: true },
            }}
          >
            <p className="dropdown-item" onClick={this.toggleViewer}>
              OLE Viewer
            </p>
          </Link>
          <Link
            to={{
              pathname: "/landing/innovative-solutions/marketplace",
              state: { sendfromactivemarket: true },
            }}
          >
            <p className="dropdown-item" onClick={this.toggleMarketPlace}>
              OLE MarketPlace
            </p>
          </Link>
        </DropdownMenu>
      </Dropdown>
    );
  }
}
