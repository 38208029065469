// import React from 'react';

// export default function Main() {
//    return <div>Main</div>;
// }

import React, { Component } from "react";
import { Navbar } from "component/Common";
export class Main extends Component {
  render() {
    let { children } = this.props;

    return (
      <>
        <Navbar />
        {children}
      </>
    );
  }
}
