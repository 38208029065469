export const HomeActionType = {
  updateHomePageData: "UPDATE_HOME_PAGE_DETAILS",
};

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";
export const BackFunctionality = {
  manufactureView: "MANUFACTURE_VIEW",
  manufactureViewCatelogue: "MANUFACTURE_VIEW_CATELOUGE",
  retailerView: "RETAILER_VIEW",
  retailerViewCatelogue: "RETAILER_VIEW_CATELOUGE",
};
