import { combineReducers } from "redux";
import home from "./home";
import productReducer from "../redux/product/product.reducer";
import userLoginReducer from "./userLogin";
import BackFunctionality from "./BackFunctionality";

export const reducers = combineReducers({
  home,
  product: productReducer,
  userLogin: userLoginReducer,
  BackFunctionality,
});
