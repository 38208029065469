import React, { Component } from 'react';
import Loader from 'react-loader-spinner';

export const CommonPageLoader = () => {
   return (
      <div className='w-100 d-flex justify-content-center py-5 bg-white'>
         <Loader type='Circles' color='#77DBA7' height={50} width={50} />
      </div>
   );
};
