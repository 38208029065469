import React from 'react';
import './style.scss';

export const CommonTextArea = ({
   className = 'form-control',
   placeholder = '',
   onChange,
   value = '',
   name,
   disabled = false,
   resize,
   rows = '5',
   maxlength,
   label = '',
   required = false,
}) => {
   return (
      <div className='normal-text-area-input'>
         <div className='d-flex'>
            {/* <div className='label-area'>
               {label !== '' ? (
                  <div className='fs-16 text-black-32 font-weight-bold line-height-19'>
                     {label}{' '}
                     {required ? (
                        <span className='text-danger font-weight-bold pl-2 fs-18'>
                           *
                        </span>
                     ) : (
                        ''
                     )}
                  </div>
               ) : (
                  ''
               )}
            </div> */}
            <div className='w-100'>
               <textarea
                  maxLength={maxlength}
                  rows={rows}
                  className={`${className} ${!resize ? 'resize-none' : ''}`}
                  name={name}
                  disabled={disabled}
                  value={value}
                  placeholder={placeholder}
                  autoComplete='off'
                  onChange={(e) => {
                     let body = {};

                     body = {
                        target: {
                           name: e.target.name,
                           value: e.target.value,
                        },
                     };
                     onChange(body);
                  }}
               ></textarea>
            </div>
         </div>
      </div>
   );
};
