import React, { useState } from 'react';
import './NormalInput.scss';
// const eye_hide = require('assets/images/Login/eye-hidden.svg');
export const NormalInput = ({
   className = 'form-control',
   placeholder = '',
   label = '',
   onChange,
   value = '',
   name,
   disabled = false,
   type = 'text',
   isLink = false,
   required = false,
   isPassIcon = false,
}) => {
   const [showIcon, setShowIcon] = useState(false);
   const toggleShow = () => {
      setShowIcon(!showIcon);
   };
   return (
      <div className={`normal-input ${isPassIcon && 'position-relative'}`}>
         {label !== '' ? (
            <div className='fs-17 text-grey fw-600 pb-2 mb-1 line-height-20'>
               {label}{' '}
               {required && <span className='text-danger pl-2 fs-18'>*</span>}
            </div>
         ) : (
            ''
         )}
         <input
            className={`${isLink ? `${className} islink` : className}`}
            name={name}
            type={type === 'password' ? (showIcon ? 'text' : 'password') : type}
            disabled={disabled}
            value={value}
            min={0}
            placeholder={placeholder}
            onChange={(e) => {
               let body = {};

               body = {
                  target: {
                     name: e.target.name,
                     value: e.target.value,
                  },
               };

               onChange(body);
            }}
         />
         {isLink ? (
            <span className='link-area'>
               <i className='fs-24 icon-link'></i>
            </span>
         ) : (
            ''
         )}
         {/* {isPassIcon ? (
            <span className='link-area cursor-pointer' onClick={toggleShow}>
               {showIcon ? (
                  <i className={`fs-24 icon-eye`}></i>
               ) : (
                  <img className='pass-hidden-img' src={eye_hide} />
               )}
            </span>
         ) : (
            ''
         )} */}
      </div>
   );
};
