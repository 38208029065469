const routers = [
  {
    path: "/",
    redirect: "/landing/home",
  },
  {
    component: "AuthLayout",
    path: "/auth",
    auth: false,
    name: "Auth",
    exact: false,
    redirect: "/auth/login",
    childrens: [
      {
        component: "Login",
        path: "/login/:id",
        componentPath: "pages/Auth/Login",
        name: "Login",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "MainLayout",
    path: "/dashboard",
    auth: false,
    name: "Dashboard",
    exact: false,
    childrens: [
      {
        component: "Login",
        path: "/login",
        componentPath: "pages/Dashboard/Main",
        name: "LoginDashboard",
        auth: false,
        exact: true,
      },
      {
        component: "UploadHistory",
        path: "/UploadHistory",
        componentPath: "pages/Dashboard/UploadHistory",
        name: "UplaodHistory",
        auth: false,
        exact: true,
      },
      {
        component: "LandingDrag",
        path: "/drag",
        componentPath: "pages/Dashboard/LandingDrag",
        name: "LandingDrag",
        auth: false,
        exact: true,
      },
      {
        component: "Errors",
        path: "/errors",
        componentPath: "pages/Dashboard/Errors",
        name: "Errors",
        auth: false,
        exact: true,
      },
      {
        component: "List",
        path: "/list",
        componentPath: "pages/marketplace/Dashboard/List",
        name: "List",
        auth: false,
        exact: true,
      },
      {
        component: "Catalogue",
        path: "/catalogue",
        componentPath: "pages/marketplace/Dashboard/Catalogue",
        name: "Catalogue",
        auth: false,
        exact: true,
      },
      {
        component: "Product",
        path: "/product/:mfg",
        componentPath: "pages/Dashboard/Product",
        name: "Product",
        auth: false,
        exact: false,
      },
    ],
  },
  {
    component: "LandingLayout",
    path: "/landing",
    auth: false,
    name: "Landing",
    exact: false,
    redirect: "/landing/home",
    childrens: [
      {
        component: "Home",
        path: "/home",
        componentPath: "pages/Landing/Home",
        name: "HomePage",
        auth: false,
        exact: true,
      },
      {
        component: "WhoWeAre",
        path: "/who-we-are",
        componentPath: "pages/Landing/WhoWeAre",
        name: "WhoWeAre",
        auth: false,
        exact: true,
      },
      {
        component: "InnovativeSolutions",
        path: "/innovative-solutions/:type",
        componentPath: "pages/Landing/InnovativeSolutions",
        name: "InnovativeSolutions",
        auth: false,
        exact: true,
      },
      {
        component: "ContactUs",
        path: "/contact-us",
        componentPath: "pages/Landing/ContactUs",
        name: "ContactUs",
        auth: false,
        exact: true,
      },
      {
        component: "RetailerLogin",
        path: "/retailer/login",
        componentPath: "pages/Landing/Retailer/Login",
        name: "LoginRetail",
        auth: false,
        exact: true,
      },

      {
        component: "Table",
        path: "/table",
        componentPath: "pages/Landing/table",
        name: "table",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    path: "/retailer",
    redirect: "/landing/retailer/login",
  },
  {
    component: "MainLayout",
    path: "/marketplace/dashboard",
    auth: false,
    name: "Dashboard",
    exact: false,
    childrens: [
      {
        component: "CartDetail",
        path: "/cart",
        componentPath: "pages/Retailer/CartDetail",
        name: "CartDetail",
        auth: false,
        exact: true,
      },
      {
        component: "OrderHistory",
        path: "/order-history",
        componentPath: "pages/Retailer/OrderHistory",
        name: "OrderHistory",
        auth: false,
        exact: true,
      },
      {
        component: "List",
        path: "/list",
        componentPath: "pages/Retailer/List",
        name: "List",
        auth: false,
        exact: true,
      },
      {
        component: "OrderConfirmed",
        path: "/order-confirm",
        componentPath: "pages/Retailer/OrderConfirmed",
        name: "OrderConfirmed",
        auth: false,
        exact: true,
      },
      {
        component: "RetailCatalogue",
        path: "/catalogue",
        componentPath: "pages/Retailer/RetailCatalogue",
        name: "RetailCatalogue",
        auth: false,
        exact: true,
      },
      {
        component: "Product",
        path: "/product/:mfg",
        componentPath: "pages/Retailer/Product",
        name: "Product",
        auth: false,
        exact: false,
      },
    ],
  },
  {
    component: "ViewLayout",
    path: "/view",
    auth: false,
    name: "View",
    exact: false,
    childrens: [
      {
        component: "Login",
        path: "/login",
        componentPath: "pages/View/Main",
        name: "LoginDashboard",
        auth: false,
        exact: true,
      },
      {
        component: "UploadHistory",
        path: "/UploadHistory",
        componentPath: "pages/View/UploadHistory",
        name: "UplaodHistory",
        auth: false,
        exact: true,
      },
      {
        component: "List",
        path: "/list",
        componentPath: "pages/View/List",
        name: "List",
        auth: false,
        exact: true,
      },
      {
        component: "Manufacturer",
        path: "/Manufacturer",
        componentPath: "pages/View/Manufacturer",
        name: "Manufacturer",
        auth: false,
        exact: true,
      },
      {
        component: "Retailer",
        path: "/Retailer",
        componentPath: "pages/View/Retailer",
        name: "Retailer",
        auth: false,
        exact: true,
      },
      {
        component: "Catalogue",
        path: "/catalogue",
        componentPath: "pages/View/Catalogue",
        name: "Catalogue",
        auth: false,
        exact: true,
      },
      {
        component: "Product",
        path: "/product",
        componentPath: "pages/View/Product",
        name: "Product",
        auth: false,
        exact: false,
      },
      {
        component: "Basic",
        path: "/product/basic",
        componentPath: "pages/View/Basic",
        name: "Basic",
        auth: false,
        exact: true,
      },
      {
        component: "Metal",
        path: "/product/metal",
        componentPath: "pages/View/Metal",
        name: "Metal",
        auth: false,
        exact: true,
      },
      {
        component: "Diamond",
        path: "/product/diamond",
        componentPath: "pages/view/Diamond",
        name: "Diamond",
        auth: false,
        exact: true,
      },
      {
        component: "Gem",
        path: "/product/gem",
        componentPath: "pages/View/Gem",
        name: "Gem",
        auth: false,
        exact: true,
      },
      {
        component: "Numeric",
        path: "/product/numeric",
        componentPath: "pages/View/Numeric",
        name: "Numeric",
        auth: false,
        exact: true,
      },
    ],
  },
];

export default routers;
