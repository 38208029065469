import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { history } from "helpers";

import "./index.scss";
const logo = require("assets/images/Landing/logo.svg");
const email = require("assets/images/Landing/email.svg");

export class Footer extends Component {
  goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  render() {
    return (
      <div className="footer">
        <div className="container-sa h-100">
          <Row className="h-100">
            <Col sm="12" lg="4">
              <div className="mt-5">
                <div>
                  <img src={logo} alt="logo" className="logo" />
                </div>
                <div className="footer-content">
                  <h5>For a more efficient tomorrow</h5>
                </div>
                <div className="social-media">
                  <span className="icon-Instagram mr-3"></span>
                  <span className="icon-youtube mr-3"></span>
                  <span className="icon-Linkedin"></span>
                </div>
              </div>
            </Col>
            <Col sm="0" lg="1"></Col>
            <Col sm="12" lg="7">
              <Row className="footer-links-container">
                <Col sm="12" md="4">
                  <div>
                    <div className="footer-heading mb-3">
                      <h5>Company</h5>
                    </div>

                    <div className="mb-2 link-container">
                      <Link to="/landing/who-we-are">Who We Are</Link>
                    </div>
                    <div className="mb-2 link-container" onClick={this.goToTop}>
                      <Link to="/landing/innovative-solutions/default">
                        Innovative Solutions
                      </Link>
                    </div>
                    <div className="mb-2 link-container">
                      <Link to="/landing/contact-us">Contact us</Link>
                    </div>
                  </div>
                </Col>
                <Col sm="0" md="2">
                  {/* <div>
                              <div className='footer-heading mb-3'>
                                 <h5>Support</h5>
                              </div>
                              <div className='mb-2'>
                                 <Link to='/landing/our-vision'>
                                    Innovation
                                 </Link>
                              </div>
                           </div> */}
                </Col>
                <Col sm="12" md="6">
                  <div>
                    <div className="footer-heading mb-3">
                      <h5>Contact Us</h5>
                    </div>
                    <div className="footer-contents">
                      <img src={email} className="contact-img mr-3" />
                      <h5 className="email">sales@ole-solutions.com</h5>
                    </div>
                    <div className="footer-contents">
                      <span className="icon-map-pin mr-3 contact-img pl-1"></span>
                      <h5>
                        Naman Midtown, Tower B- 802,
                        <br /> Senapati Bapat Marg, Elphinstone, Maharashtra,
                        Mumbai 400013
                      </h5>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
