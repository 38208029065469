import React from "react";
// import NavLink from "reactstrap/lib/NavLink";
import "./index.scss";
import { useHistory, Link } from "react-router-dom";

export const ProductNavbar = () => {
  const history = useHistory();

  const handleclick = () => {
    history.push("./product");
  };
  return (
    <div className="metalinformationContainer">
      <div className="link">
        <div className="basic" onClick={handleclick}>
          <Link>Basic Information</Link>
        </div>
        <div className="metal" onClick={handleclick}>
          <Link>Metal information</Link>
        </div>
        <div className="Diamond" onClick={handleclick}>
          <Link>Diamond information</Link>
        </div>
        <div className="Gem" onClick={handleclick}>
          <Link>Gem information</Link>
        </div>
      </div>
    </div>
  );
};

// export default ProductNavbar;
