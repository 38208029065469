import { Main } from "pages/View/Main";
import React, { Component } from "react";

export class ViewLayout extends Component {
  render() {
    let { children } = this.props;

    return (
      <>
        {/* <h1>MainLayout layout</h1> */}
        <Main />
        {children}
      </>
    );
  }
}
