import { BackFunctionality } from "service/actionType";

const initialState = {
  manufacturetabList: {
    sort: 10,
    filter: "",
    page: 1
  },
  manufacturetabCatelogoue: {
    sort: 10,
    filter: "",
    page: 1
  },
  retailertabList: {
    sort: 10,
    filter: "",
    page: 1
  },
  retailertabCatelogue: {
    sort: 10,
    filter: "",
    page: 1
  },
};

export default (state = Object.assign({}, initialState), { type, payload }) => {
  switch (type) {
    case BackFunctionality.manufactureView:
      return {
        ...state,
        manufacturetabList: payload,
      };
    case BackFunctionality.manufactureViewCatelogue:
      return {
        ...state,
        manufacturetabCatelogoue: payload,
      };
    case BackFunctionality.retailerView:
      return {
        ...state,
        retailertabList: payload,
      };
    case BackFunctionality.retailerViewCatelogue:
      return {
        ...state,
        retailertabCatelogue: payload,
      };
    default:
      return state;
  }
};
