import {
  FETCH_ALL_PRODUCTS
} from './product.types'

const initialState = {
  data: {}
}

const productReducer = (state = initialState, action) => {
  switch(action.type) {
    case FETCH_ALL_PRODUCTS: return {
      ...state,
      data: action.payload
    }
    default: return state
  }
}

export default productReducer