import React, { Component } from 'react';
import { Navbar, Footer } from 'component/Common';
export class LandingLayout extends Component {
   render() {
      let { children } = this.props;

      return (
         <>
            <Navbar />
            {children}
            <Footer />
         </>
      );
   }
}
